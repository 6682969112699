import React from 'react';

import MultiSelectInput from './MultiSelectInput';

type Props = {
  handleChange: (value: any) => void;
  data: any[];
  value: any;
};

export default function UsersInput(props: Props) {
  const { data, handleChange, value } = props;

  const users = data.map(([id, firstName, lastName, type, organisationName]) => {
    const label = generateLabel(type, firstName, lastName, organisationName);

    return {
      label,
      value: parseInt(id),
    };
  });

  users.unshift({ label: 'Alle Vrijwilligers', value: 'ALL_VOLUNTEERS' });

  return (
    <MultiSelectInput
      handleChange={handleChange}
      name="usersMultiInput"
      options={users}
      placeholder="Users"
      value={value}
    />
  );

  function generateLabel(type, firstName, lastName, organisationName) {
    const isUserPerson = ['Organizer', 'Volunteer'].includes(type);

    return isUserPerson ? `${firstName} ${lastName} - ${type}` : `${organisationName} - ${type}`;
  }
}
