import React from 'react';
import { CellValue } from 'react-table';

type Props = {
  value: CellValue;
};

function MainTextCell({ value }: Props) {
  return (
    <p className="px-3 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-700">
      {value}
    </p>
  );
}

export default MainTextCell;
