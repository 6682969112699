import SecondaryText from 'components/inputs/SecondaryText';
import React from 'react';
import { CellValue } from 'react-table';

type Props = {
  value: CellValue;
};

export default function NameCell({ value }: Props) {
  if (value.type === 'Volunteer' || value.type === 'Organizer') {
    return <SecondaryText value={`${value.first_name} ${value.last_name}`} />;
  }

  return <SecondaryText value={`${value.organisation_name}`} />;
}
