import React from 'react';

type Props = {
  name: string;
  handleChange: (value: string) => void;
  options: { label: string; value: string }[];
  label: string;
  value: string;
  helperText?: string;
};

export default function RadioInput(props: Props) {
  const { name, options, label, value, handleChange, helperText } = props;

  return (
    <div className="mt-6">
      <legend className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
        {label}
      </legend>

      {helperText && <p className="text-sm leading-5 text-gray-500">{helperText}</p>}

      <div className="mt-4">
        {options.map((option, index) => {
          return (
            <div key={index} className="flex items-center py-1">
              <input
                checked={value === option.value}
                id={option.value}
                className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                name={name}
                onChange={() => handleChange(option.value)}
                type="radio"
              />
              <label htmlFor={option.value} className="ml-3">
                <span className="block text-sm leading-5 font-medium text-gray-700">
                  {option.label}
                </span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
