import { Inertia } from '@inertiajs/inertia';
import PageLayout from 'pages/PageLayout';
import React from 'react';

import Form from './Form';

type Props = {
  activity_type: any;
};

export default function Edit(props: Props) {
  const { activity_type } = props;

  return <Form initialValues={activity_type} onSubmit={handleSubmit} />;

  async function handleSubmit(values: any) {
    await Inertia.put(`/activity_types/${activity_type.id}`, values);
  }
}

Edit.layout = (page: React.ReactNode) => <PageLayout title="Edit Activity Type">{page}</PageLayout>;
