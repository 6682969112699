import TextInput from 'components/inputs/TextInput';
import { FormikHelpers, useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

type Props = {
  initialValues?: any;
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
};

function Form(props: Props) {
  const { initialValues, onSubmit } = props;

  const {
    handleSubmit,
    setFieldTouched,
    errors,
    touched,
    setFieldValue,
    values,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: Object.assign(
      {},
      {
        name: '',
        notes: '',
      },
      initialValues,
    ),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit,
    validationSchema,
    isInitialValid: false,
  });

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-6 sm:mt-5">
        <TextInput
          error={touched.name ? errors.name : ''}
          handleBlur={() => setFieldTouched('name')}
          handleChange={(value) => setFieldValue('name', value)}
          label="Name"
          name="name"
          placeholder="Catering"
          value={values.name}
        />

        <TextInput
          handleBlur={() => setFieldTouched('notes')}
          handleChange={(value) => setFieldValue('notes', value)}
          isOptional
          label="Opmerkingen"
          name="notes"
          placeholder="Always needs 2 volunteers"
          value={values.notes}
        />

        <div className="border-t border-gray-300 pt-3 mt-6">
          <button
            disabled={!isValid || isSubmitting}
            type="submit"
            className="inline-flex disabled:bg-gray-300 items-center shadow-sm px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  name: Yup.string().required('Please provide an activity type name.'),
});

export default Form;
