import AvailabilityInput from 'components/inputs/AvailabilityInput';
import { FormikHelpers, useFormik } from 'formik';
import React, { useEffect } from 'react';

type Props = {
  initialValues?: any;
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
};

export default function Form(props: Props) {
  const { initialValues, onSubmit } = props;

  const { handleSubmit, validateForm, setFieldValue, values } = useFormik({
    initialValues: Object.assign(
      {},
      {
        availabilities_attributes: [],
      },
      initialValues,
    ),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit,
    isInitialValid: false,
  });

  useEffect(() => {
    validateForm();
  }, [values.availabilities_attributes]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-6 sm:mt-5">
        <AvailabilityInput
          value={values.availabilities_attributes}
          handleChange={(availabilities) =>
            setFieldValue('availabilities_attributes', availabilities)
          }
        />

        <div className="border-t border-gray-300 pt-3 mt-6">
          <button
            type="submit"
            className="inline-flex disabled:bg-gray-300 items-center shadow-sm px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
