import React from 'react';

type Props = {
  error?: string;
  handleBlur: () => void;
  handleChange: (value: string) => void;
  helperText?: string;
  isOptional?: boolean;
  label: string;
  name: string;
  placeholder: string;
  type?: string;
  value: string;
};

function TextInput(props: Props) {
  const {
    value,
    label,
    isOptional,
    handleChange,
    handleBlur,
    helperText,
    type,
    error,
    name,
    placeholder,
  } = props;

  return (
    <div className="mt-6 max-w-md">
      <div className="flex justify-between">
        <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-700">
          {label}
        </label>
        {isOptional && <span className="text-sm leading-5 text-gray-500">Optional</span>}
      </div>

      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          onBlur={handleBlur}
          className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md ${
            error ? 'border-red-300 text-red-900 focus:border-red-300' : ''
          }`}
          id={name}
          name={name}
          onChange={(event) => handleChange(event.target.value)}
          placeholder={placeholder}
          type={type || 'text'}
          value={value}
        />
      </div>
      {helperText && <p className="mt-2 text-sm text-gray-500">{helperText}</p>}
      {error && <p className="text-sm text-red-600">{error}</p>}
    </div>
  );
}

export default TextInput;
