import { Inertia } from '@inertiajs/inertia';
import { InertiaLink, usePage } from '@inertiajs/inertia-react';
import LongTextCell from 'components/inputs/LongTextCell';
import { TextWithLinks } from 'components/TextWithLinks';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import ReactToPrint from 'react-to-print';

type Props = {
  project: any;
  printActivityNotes: boolean;
  printDescription: boolean;
};

export default function Callsheet(props: Props) {
  const { project, printDescription, printActivityNotes } = props;

  const printRef = React.useRef<HTMLDivElement>(null);

  const page = usePage();

  const [activitiesToPrint, setActivitiesToPrint] = useState(project.activities);

  return (
    <div className="bg-white shadow overflow-hidden mb-6 sm:rounded-lg">
      <div className="px-4 py-5 flex justify-between border-b border-gray-200 sm:px-6">
        <h3 className="text-lg leading-6 font-medium" style={{ color: project.color }}>
          Callsheet - {project.code}
        </h3>
        <ReactToPrint
          trigger={() => (
            <div className="flex items-center cursor-pointer">
              <svg
                className="mr-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17 17H19C20.1046 17 21 16.1046 21 15V11C21 9.89543 20.1046 9 19 9H5C3.89543 9 3 9.89543 3 11V15C3 16.1046 3.89543 17 5 17H7M9 21H15C16.1046 21 17 20.1046 17 19V15C17 13.8954 16.1046 13 15 13H9C7.89543 13 7 13.8954 7 15V19C7 20.1046 7.89543 21 9 21ZM17 9V5C17 3.89543 16.1046 3 15 3H9C7.89543 3 7 3.89543 7 5V9H17Z"
                  stroke="#4A5568"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <h3>Print</h3>
            </div>
          )}
          pageStyle="padding: 40;"
          content={() => printRef.current}
        />
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">Title</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {project.title} ({project.code})
            </dd>
          </div>
          {project.location && (
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-medium text-gray-500">Principal Venue</dt>
              <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                <p>{project.location.name}</p>
                <p>{project.location.address_line_1}</p>
                <p>{project.location.address_line_2}</p>
              </dd>
            </div>
          )}
          <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">Artist</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {project.artist
                ? project.artist.organisation_name
                : 'No artist is linked to this project.'}
            </dd>
          </div>
          {project.start_datetime && (
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-medium text-gray-500">Principal Start Time</dt>
              <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                <p>{dayjs(project.start_datetime).format('dddd, MMMM D')}</p>
                <p>{dayjs(project.start_datetime).format('HH:mm')}</p>
              </dd>
            </div>
          )}

          {project.description && (
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-medium text-gray-500">Description</dt>
              <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                <TextWithLinks>{project.description}</TextWithLinks>
              </dd>
            </div>
          )}

          <div className="mt-8 sm:mt-0 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500 mb-2">Activities</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0">
              <table className="min-w-full border rounded border-gray-200 shadow">
                <thead>
                  <tr>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      What
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Start
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      End
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Venue
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Who
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Notes
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Print
                    </th>
                  </tr>
                </thead>
                <tbody className="overflow-x-scroll">
                  {project.activities.map((activity: any) => {
                    const users = activity.users
                      .map((user) => {
                        return user.first_name
                          ? `${user.first_name} ${user.last_name[0]}.`
                          : user.organisation_name;
                      })
                      .join(', ');

                    return (
                      <tr key={activity.id} className="odd:bg-white even:bg-gray-50">
                        <td className="px-6 py-4 text-sm leading-5 font-medium text-gray-900">
                          {activity.activity_type.name}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700">
                          <p>{dayjs(activity.start_time).format('ddd, MMM D')}</p>
                          <p>{dayjs(activity.start_time).format('HH:mm')}</p>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700">
                          <p>{dayjs(activity.end_time).format('ddd, MMM D')}</p>
                          <p>{dayjs(activity.end_time).format('HH:mm')}</p>
                        </td>
                        <td className="px-6 py-4 break-normal text-sm leading-5 text-gray-700">
                          {activity.location.name}
                        </td>
                        <td className="px-6 py-4 break-normal text-sm leading-5 text-gray-700">
                          <LongTextCell value={users} />
                        </td>
                        <td className="px-6 break-words py-4 text-sm leading-5 text-gray-700">
                          <LongTextCell value={activity.notes} />
                        </td>
                        <td className="px-6 break-words py-4 text-sm leading-5 text-indigo-600 hover:text-indigo-800">
                          <button onClick={() => handleEdit(activity.id)}>Edit</button>
                          <span className="text-indigo-600"> | </span>
                          <InertiaLink method="delete" href={`/activities/${activity.id}`}>
                            <button>Delete</button>
                          </InertiaLink>
                        </td>
                        <td className="px-6 break-words py-4 text-sm leading-5 text-indigo-600 hover:text-indigo-800">
                          <input
                            id="print_include_activity"
                            type="checkbox"
                            checked={activityWillBePrinted(activity)}
                            onClick={() => togglePrintActivity(activity)}
                            className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </dd>
          </div>
        </dl>
      </div>

      <div className="hidden">
        <div ref={printRef} className="overflow-hidden">
          <div className="border-b border-gray-200 mb-4">
            <h3 className="text-md leading-6 font-medium" style={{ color: project.color }}>
              Callsheet - {project.code}
            </h3>
          </div>
          <div>
            <dl>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">Title</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {project.title} ({project.code})
                </dd>
              </div>
              <div className="mt-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200">
                <dt className="text-sm leading-5 font-medium text-gray-500">Principal Venue</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {project.location ? (
                    <>
                      <p>{project.location.name}</p>
                      <p>{project.location.address_line_1}</p>
                      <p>{project.location.address_line_2}</p>
                    </>
                  ) : (
                    <p>No venue is linked to this project.</p>
                  )}
                </dd>
              </div>
              <div className="mt-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200">
                <dt className="text-sm leading-5 font-medium text-gray-500">Artist</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {project.artist
                    ? project.artist.organisation_name
                    : 'No artist is linked to this project.'}
                </dd>
              </div>

              {project.start_datetime && (
                <div className="mt-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200">
                  <dt className="text-sm leading-5 font-medium text-gray-500">
                    Principal Start Time
                  </dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    <p>{dayjs(project.start_time).format('dddd, MMMM D')}</p>
                    <p>{dayjs(project.start_time).format('HH:mm')}</p>
                  </dd>
                </div>
              )}

              {project.description && printDescription && (
                <div className="mt-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200">
                  <dt className="text-sm leading-5 font-medium text-gray-500">Description</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {project.description}
                  </dd>
                </div>
              )}

              <div className="mt-3 sm:border-t sm:border-gray-200">
                <dt className="text-sm leading-5 font-medium text-gray-500 mb-2">Activities</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0">
                  <table className="min-w-full border border-gray-800">
                    <thead>
                      <tr>
                        <th className="px-3 py-2 border-b border-gray-800 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-900 uppercase tracking-wider">
                          What
                        </th>
                        <th className="px-3 py-2 border-b border-gray-800 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-900 uppercase tracking-wider">
                          Start
                        </th>
                        <th className="px-3 py-2 border-b border-gray-800 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-900 uppercase tracking-wider">
                          End
                        </th>
                        <th className="px-3 py-2 border-b border-gray-800 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-900 uppercase tracking-wider">
                          Venue
                        </th>
                        <th className="px-3 py-2 border-b border-gray-800 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-900 uppercase tracking-wider">
                          Who
                        </th>
                        {printActivityNotes && (
                          <th className="px-3 py-2 border-b border-gray-800 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-900 uppercase tracking-wider">
                            Notes
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {activitiesToPrint.map((activity: any) => {
                        return (
                          <tr key={activity.id} className="odd:bg-white even:bg-gray-50">
                            <td className="px-3 py-1 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
                              {activity.activity_type.name}
                            </td>
                            <td className="px-3 py-1 whitespace-no-wrap text-xs leading-5 text-gray-900">
                              <p>{dayjs(activity.start_time).format('ddd, D/M, HH:mm')}</p>
                            </td>
                            <td className="px-3 py-1 whitespace-no-wrap text-xs leading-5 text-gray-900">
                              <p>{dayjs(activity.end_time).format('ddd, D/M, HH:mm')}</p>
                            </td>
                            <td className="px-3 py-1 break-normal text-xs leading-5 text-gray-900">
                              {activity.location.name}
                            </td>
                            <td className="px-3 py-1 break-normal text-xs leading-5 text-gray-900">
                              {activity.users.map((user: any) => {
                                const lastIndex = activity.users[activity.users.length - 1].id;

                                return (
                                  <span key={user.id}>
                                    {user.first_name
                                      ? `${user.first_name} ${user.last_name[0]}.`
                                      : user.organisation_name}
                                    {lastIndex !== user.id && ', '}
                                  </span>
                                );
                              })}
                            </td>
                            {printActivityNotes && (
                              <td className="px-3 w-64 break-words py-4 text-sm leading-4 text-gray-900">
                                <TextWithLinks>{activity.notes}</TextWithLinks>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );

  function activityWillBePrinted(activity) {
    return !!activitiesToPrint.filter((activityToPrint) => activityToPrint.id === activity.id)
      .length;
  }

  function handleEdit(activityId) {
    Inertia.get(`/activities/${activityId}/edit`, { referer: page.url }, {});
  }

  function togglePrintActivity(activity) {
    if (activityWillBePrinted(activity)) {
      const newActivitesToPrint = activitiesToPrint.filter(
        (activityToPrint) => activityToPrint.id !== activity.id,
      );

      setActivitiesToPrint(newActivitesToPrint);
    } else {
      setActivitiesToPrint([...activitiesToPrint, activity]);
    }
  }
}
