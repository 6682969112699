import 'react-datepicker/dist/react-datepicker.css';

import ActivityTypeInput from 'components/inputs/ActivityTypeInput';
import DateTimeRangeInput from 'components/inputs/DateTimeRangeInput';
import LocationInput from 'components/inputs/LocationInput';
import ProjectInput from 'components/inputs/ProjectInput';
import TextInput from 'components/inputs/TextInput';
import UserInput from 'components/inputs/UserInput';
import VolunteerInput from 'components/inputs/VolunteerInput';
import be from 'date-fns/locale/en-GB';
import { FormikHelpers, useFormik } from 'formik';
import { usePrevious } from 'hooks/usePrevious';
import React, { useEffect } from 'react';
import { registerLocale } from 'react-datepicker';
import * as Yup from 'yup';

registerLocale('be', be);

type Props = {
  initialValues?: any;
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
  activity_types: any[];
  locations: any[];
  projects: any[];
  updateTimeframe: (start_time: string, end_time: string) => void;
  availableVolunteers: any[];
  bookedVolunteers: any[];
  otherUsers: any[];
};

function Form(props: Props) {
  const {
    availableVolunteers,
    bookedVolunteers,
    initialValues,
    onSubmit,
    activity_types,
    locations,
    projects,
    updateTimeframe,
    otherUsers,
  } = props;

  const {
    handleSubmit,
    setFieldTouched,
    errors,
    touched,
    setFieldValue,
    values,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: Object.assign(
      {},
      {
        activity_type_id: null,
        end_time: null,
        location_id: null,
        notes: '',
        internal_review: '',
        project_id: null,
        start_time: null,
        volunteer_user_activities: [],
        other_user_activities: [],
      },
      initialValues,
    ),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit,
    validationSchema,
    isInitialValid: false,
  });

  const previousStartTime = usePrevious(values.start_time);
  const previousEndTime = usePrevious(values.end_time);

  useEffect(() => {
    if ((!previousStartTime || !previousEndTime) && values.start_time && values.end_time) {
      return updateTimeframe(values.start_time, values.end_time);
    }

    if (values.start_time && values.end_time) {
      updateTimeframe(values.start_time, values.end_time);
      setFieldValue('volunteer_user_activities', []);
    }
  }, [values.start_time, values.end_time]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-6 sm:mt-5">
        <ProjectInput
          data={projects}
          value={values.project_id}
          handleChange={(value) => setFieldValue('project_id', value)}
        />

        <LocationInput
          data={locations}
          value={values.location_id}
          handleChange={(value) => setFieldValue('location_id', value)}
        />

        <ActivityTypeInput
          data={activity_types}
          value={values.activity_type_id}
          handleChange={(value) => setFieldValue('activity_type_id', value)}
        />

        <DateTimeRangeInput
          startTimeValue={values.start_time}
          endTimeValue={values.end_time}
          handleStartTimeChange={(value) => setFieldValue('start_time', value)}
          handleEndTimeChange={(value) => setFieldValue('end_time', value)}
        />

        <UserInput
          users={otherUsers}
          value={values.other_user_activities}
          handleChange={(value) => setFieldValue('other_user_activities', value)}
        />

        <TextInput
          isOptional
          error={touched.notes ? errors.notes : ''}
          handleBlur={() => setFieldTouched('notes')}
          handleChange={(value) => setFieldValue('notes', value)}
          label="Remarks"
          name="notes"
          placeholder="At least 1 technician needed"
          value={values.notes}
        />

        <TextInput
          isOptional
          error={touched.internal_review ? errors.internal_review : ''}
          handleBlur={() => setFieldTouched('internal_review')}
          handleChange={(value) => setFieldValue('internal_review', value)}
          label="Internal Evaluation"
          name="internal_review"
          placeholder="Went great!"
          value={values.internal_review}
        />

        <VolunteerInput
          value={values.volunteer_user_activities}
          handleChange={(value) => setFieldValue('volunteer_user_activities', value)}
          availableVolunteers={availableVolunteers}
          bookedVolunteers={bookedVolunteers}
        />

        <div className="border-t border-gray-300 pt-3 mt-6">
          <button
            disabled={!isValid || isSubmitting}
            type="submit"
            className="inline-flex disabled:bg-gray-300 items-center shadow-sm px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  location_id: Yup.string().required('Please select a venue.'),
  project_id: Yup.string().required('Please select a project.'),
  activity_type_id: Yup.string().required('Please select an activity type.'),
  start_time: Yup.date().required('Please select a start time.'),
  end_time: Yup.date().required('Please select a start time.'),
});

export default Form;
