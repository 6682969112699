import { Inertia } from '@inertiajs/inertia';
import PageLayout from 'pages/PageLayout';
import React from 'react';

import Form from './Form';

type Props = {
  activity_types: any[];
  activity: any;
  locations: any[];
  projects: any[];
  available_volunteers: any[];
  booked_volunteers: any[];
  other_users: any[];
  referer: string;
};

export default function Edit(props: Props) {
  const {
    activity,
    activity_types,
    locations,
    projects,
    available_volunteers,
    booked_volunteers,
    other_users,
    referer,
  } = props;

  return (
    <Form
      initialValues={activityFormatted()}
      activity_types={activity_types}
      locations={locations}
      projects={projects}
      onSubmit={handleSubmit}
      updateTimeframe={updateTimeframe}
      availableVolunteers={available_volunteers}
      bookedVolunteers={booked_volunteers}
      otherUsers={other_users}
    />
  );

  async function handleSubmit(values: any) {
    const initialUserActivities = activity.user_activities;

    const currentUserActivities = [
      ...values.volunteer_user_activities,
      ...values.other_user_activities,
    ];

    const user_activities_attributes = [];

    currentUserActivities.forEach((userActivity) => {
      const existingUserActivity = initialUserActivities.find(
        (initialUserActivity) => initialUserActivity.user_id === userActivity.user_id,
      );

      if (existingUserActivity) {
        user_activities_attributes.push(existingUserActivity);
      } else {
        user_activities_attributes.push(userActivity);
      }
    });

    initialUserActivities.forEach((initialUserActivity) => {
      const existingUserActivity = currentUserActivities.find(
        (userActivity) => initialUserActivity.user_id === userActivity.user_id,
      );

      if (!existingUserActivity) {
        user_activities_attributes.push({ ...initialUserActivity, _destroy: true });
      }
    });

    await Inertia.put(`/activities/${activity.id}`, {
      ...values,
      user_activities_attributes,
      referer,
    });
  }

  function activityFormatted() {
    return {
      ...activity,
      start_time: new Date(activity.start_time),
      end_time: new Date(activity.end_time),
      volunteer_user_activities: activity.user_activities.filter(
        (userActivity) => userActivity.user.type === 'Volunteer',
      ),
      other_user_activities: activity.user_activities.filter(
        (userActivity) => userActivity.user.type !== 'Volunteer',
      ),
    };
  }

  function updateTimeframe(start_time, end_time) {
    Inertia.visit(`/activities/${activity.id}/edit`, {
      data: {
        start_time,
        end_time,
      },
      replace: true,
      preserveState: true,
      only: ['available_volunteers', 'booked_volunteers'],
    });
  }
}

Edit.layout = (page: React.ReactNode) => <PageLayout title="Edit Activity">{page}</PageLayout>;
