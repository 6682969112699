import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime'; // load on demand
import React from 'react';
import { CellValue } from 'react-table';

dayjs.extend(RelativeTime); // use plugin

type Props = {
  value: CellValue;
};

export default function DatetimeCell({ value }: Props) {
  const dateFormatted = dayjs(value).format('ddd, D MMM');
  const timeFormatted = dayjs(value).format('HH:mm');

  return (
    <div className="px-3 py-4">
      <p className="text-sm leading-5 font-medium text-gray-700">{dateFormatted}</p>
      <p className="text-sm leading-5 text-gray-500">{timeFormatted}</p>
    </div>
  );
}
