import React from 'react';

import SelectInput from './SelectInput';

type Props = {
  handleChange: (value: any) => void;
  value: any;
  users: any[];
};

export default function UserInput(props: Props) {
  const { value, users, handleChange } = props;

  const [selectedUser, setSelectedUser] = React.useState<number | null>(null);

  const options = users
    ? users.map((user) => {
        const label = generateLabel(user);

        return {
          label,
          value: user.id,
        };
      })
    : [];

  const selectableOptions = options.filter(
    (option) =>
      !value.length || !value.some((userActivity) => userActivity.user_id === option.value),
  );

  return (
    <div className="mt-6 max-w-md">
      <label className="block text-lg font-medium leading-5 text-gray-700">Other Users</label>
      <div className="mt-1 relative rounded-md shadow-sm form-input block w-full p-4">
        <div className="flex items-center">
          <SelectInput
            name="userInput"
            placeholder="Select user"
            value={selectedUser}
            handleChange={(selectedOption) => setSelectedUser(selectedOption)}
            options={selectableOptions}
          />
          <div onClick={addUser}>
            <svg
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6 ml-2 hover:border-gray-600 cursor-pointer">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 4v16m8-8H4"></path>
            </svg>
          </div>
        </div>

        <div className="mt-3">
          <ul className="w-80">
            {value.map((userActivity) => {
              return (
                <li
                  className="mb-3 flex items-center text-sm leading-5 text-gray-800 cursor-pointer"
                  key={userActivity.user}>
                  {generateLabel(userActivity.user)}
                  <div onClick={() => removeUser(userActivity.user_id)}>
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="ml-2 w-5 h-5">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        <p className="mt-3 text-sm leading-5 text-gray-400">{value.length} user(s) selected</p>
      </div>
    </div>
  );

  function addUser() {
    if (!selectedUser) {
      return;
    }

    const userToAdd = users.find((user) => user.id === selectedUser);

    const updatedUserList = (value || []).concat([{ user_id: userToAdd.id, user: userToAdd }]);

    handleChange(updatedUserList);

    setSelectedUser(null);
  }

  function generateLabel(user) {
    const isUserPerson = ['Organizer'].includes(user.type);

    return isUserPerson
      ? `${user.first_name} ${user.last_name} - ${user.type}`
      : `${user.organisation_name} - ${user.type}`;
  }

  function removeUser(userId: number) {
    const updatedTaskList = value.filter((userActivity) => userActivity.user_id !== userId);

    handleChange(updatedTaskList);

    setSelectedUser(null);
  }
}
