import React from 'react';

import Callsheet from './Callsheet';

type Props = {
  activities: any[];
  printActivityNotes: boolean;
  printDescription: boolean;
};

function CallsheetView(props: Props) {
  const { activities, printActivityNotes, printDescription } = props;

  const [data, setData] = React.useState<any[]>([]);

  React.useEffect(() => {
    const newData = activities.reduce((accumulator, currentActivity) => {
      const { project, ...activity } = currentActivity;

      const index = accumulator.findIndex(
        (accumulatedProject: any) => accumulatedProject.id === project.id,
      );

      if (index === -1) {
        return accumulator.concat([{ ...project, activities: [activity] }]);
      } else {
        accumulator[index].activities.push(activity);
        return accumulator;
      }
    }, []);

    setData(newData);
  }, [activities]);

  return (
    <React.Fragment>
      {data.map((project) => {
        return (
          <Callsheet
            printDescription={printDescription}
            printActivityNotes={printActivityNotes}
            key={project.id}
            project={project}
          />
        );
      })}
    </React.Fragment>
  );
}

export default CallsheetView;
