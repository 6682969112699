import { usePage } from '@inertiajs/inertia-react';
import React from 'react';
import { Row, TableBodyProps } from 'react-table';

type Props = {
  actions?: (element: any) => React.ReactNode;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  page: Row<Record<string, unknown>>[];
  prepareRow: (row: Row<Record<string, unknown>>) => void;
  tableBodyProps: TableBodyProps;
  extraActions?: Array<(element: any) => React.ReactNode>;
};

function Body(props: Props) {
  const { actions, extraActions, onDelete, onEdit, page, prepareRow, tableBodyProps } = props;
  const { auth } = usePage().props;

  return (
    <tbody {...tableBodyProps}>
      {page.map((row: any, rowIndex) => {
        prepareRow(row);

        return (
          <tr key={rowIndex} className="odd:bg-white even:bg-gray-50">
            {row.cells.map((cell: any, cellIndex: any) => {
              return <td key={cellIndex}>{cell.render('Cell')}</td>;
            })}
            <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
              {actions ? (
                actions(row.original)
              ) : (
                <>
                  {extraActions &&
                    extraActions.map((action) => {
                      return (
                        <>
                          {action(row.original)}
                          <span className="text-indigo-600"> | </span>
                        </>
                      );
                    })}

                  <button
                    onClick={() => onEdit(row.original.id.toString())}
                    className="text-indigo-600 hover:text-indigo-800 focus:outline-none focus:underline">
                    Edit
                  </button>
                  {row.original.username && row.original.id === auth.user.id ? null : (
                    <>
                      <span className="text-indigo-600"> | </span>
                      <button
                        onClick={() => confirmDeletion(row.original.id.toString())}
                        className="text-indigo-600 hover:text-indigo-800 focus:outline-none focus:underline">
                        Delete
                      </button>
                    </>
                  )}
                </>
              )}
            </td>
          </tr>
        );
      })}
    </tbody>
  );

  function confirmDeletion(id: string) {
    const isDeletionConfirmed = window.confirm('Are you sure you want to delete this item?');

    isDeletionConfirmed && onDelete(id);
  }
}

export default Body;
