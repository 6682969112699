import 'react-datepicker/dist/react-datepicker.css';
const customParseFormat = require('dayjs/plugin/customParseFormat');

import be from 'date-fns/locale/en-GB';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';

registerLocale('be', be);
dayjs.extend(customParseFormat);

type Props = {
  error?: string;
  handleStartTimeChange: (value: Date) => void;
  handleEndTimeChange: (value: Date) => void;
  range?: 'future' | 'past';
  startTimeValue: Date;
  endTimeValue: Date;
};

export default function DateTimeRangeInput(props: Props) {
  const { startTimeValue, endTimeValue, handleStartTimeChange, handleEndTimeChange } = props;

  const [dateValue, setDateValue] = useState(
    startTimeValue ? dayjs(startTimeValue).format('YYYY-MM-DD') : '',
  );
  const [startTime, setStartTime] = useState(
    startTimeValue ? dayjs(startTimeValue).format('HH:mm') : '',
  );
  const [endTime, setEndTime] = useState(endTimeValue ? dayjs(endTimeValue).format('HH:mm') : '');

  return (
    <div className="flex">
      <div className="mt-6 mr-4">
        <label className="block z- text-sm font-medium leading-5 text-gray-700" htmlFor="date">
          Date
        </label>

        <input
          type="date"
          id="date"
          name="date"
          value={dateValue}
          onChange={(event) => setDateValue(event.target.value)}
        />
      </div>

      <div className="mt-6 mr-4">
        <label className="block  text-sm font-medium leading-5 text-gray-700" htmlFor="start_time">
          Start Time
        </label>
        <input
          disabled={!dateValue}
          value={startTime}
          onBlur={handleStartTimeUpdate}
          onChange={(event) => setStartTime(event.target.value)}
          type="time"
          id="start_time"
          name="start_time"
        />
      </div>

      <div className="mt-6 mr-4">
        <label
          className="block w-32 text-sm font-medium leading-5 text-gray-700"
          htmlFor="end_time">
          End Time
        </label>
        <input
          disabled={!dateValue || !startTime}
          min={startTime}
          value={endTime}
          onChange={(event) => setEndTime(event.target.value)}
          type="time"
          id="end_time"
          name="end_time"
          onBlur={handleEndTimeUpdate}
        />
      </div>
    </div>
  );

  function handleStartTimeUpdate() {
    const date = dayjs(dateValue);

    if (startTime) {
      const time = dayjs(startTime, 'HH:mm');

      const newStartTime = date.set('hour', time.hour()).set('minutes', time.minute());

      handleStartTimeChange(newStartTime.toDate());
    }
  }

  function handleEndTimeUpdate() {
    const date = dayjs(dateValue);

    if (endTime) {
      const time = dayjs(endTime, 'HH:mm');

      const newEndTime = date.set('hour', time.hour()).set('minutes', time.minute());

      handleEndTimeChange(newEndTime.toDate());
    }
  }
}
