import React from 'react';

import SelectInput from './SelectInput';

type Props<ValueType = string> = {
  error?: string;
  handleChange: (value: ValueType | null) => void;
  label: string;
  name: string;
  options: any[];
  placeholder: string;
  isClearable?: boolean;
  isOptional?: boolean;
  value: any;
};

function DropdownInput<ValueType = string>(props: Props<ValueType>) {
  const {
    value,
    isOptional,
    isClearable,
    label,
    name,
    placeholder,
    handleChange,
    error,
    options,
  } = props;

  return (
    <div className="w-80 mt-6">
      <div className="flex justify-between">
        <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-700">
          {label}
        </label>
        {isOptional && <span className="text-sm leading-5 text-gray-500">Optional</span>}
      </div>

      <SelectInput
        isClearable={isClearable}
        name={name}
        placeholder={placeholder}
        value={value}
        handleChange={handleChange}
        options={options}
      />

      {error && <p className="text-sm text-red-600">{error}</p>}
    </div>
  );
}

export default DropdownInput;
