import React from 'react';

import Body from './Body';
import Header from './Header';
import Pagination from './Pagination';

type Props = {
  actions?: (element: any) => React.ReactNode;
  extraActions?: Array<(element: any) => React.ReactNode>;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  pagy_metadata: any[];
  tableInstance: any;
};

function Table(props: Props) {
  const { actions, extraActions, tableInstance, pagy_metadata, onDelete, onEdit } = props;

  const { getTableBodyProps, headerGroups, page, prepareRow, state } = tableInstance;

  return (
    <div className="flex flex-col">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table className="min-w-full">
            <Header headerGroups={headerGroups} />
            <Body
              actions={actions}
              extraActions={extraActions}
              onDelete={onDelete}
              onEdit={onEdit}
              tableBodyProps={getTableBodyProps()}
              page={page}
              prepareRow={prepareRow}
            />
          </table>
          <Pagination {...state} pagy_metadata={pagy_metadata} />
        </div>
      </div>
    </div>
  );
}

export default Table;
