import { Inertia } from '@inertiajs/inertia';
import PageLayout from 'pages/PageLayout';
import React from 'react';
import * as Yup from 'yup';

import Form from './Form';

type Props = {
  user: any;
};

export default function Edit(props: Props) {
  const { user } = props;

  return <Form validationSchema={validationSchema} initialValues={user} onSubmit={handleSubmit} />;

  async function handleSubmit(values: any) {
    await Inertia.put(`/users/${user.id}`, values);
  }
}

Edit.layout = (page: React.ReactNode) => <PageLayout title="Edit User">{page}</PageLayout>;

const validationSchema = Yup.object({
  username: Yup.string()
    .required('Please provide a username.')
    .min(4, 'Usernames must contain at least 4 characters.'),
  password: Yup.string().min(6, 'Passwords must contain at least 6 characters.'),
  type: Yup.string().required('Please select a user type.'),
  first_name: Yup.string().when('role', {
    is: (val) => ['volunteer', 'organizer'].includes(val),
    then: Yup.string().required('Please provide a first name.'),
  }),
  last_name: Yup.string().when('role', {
    is: (val) => ['volunteer', 'organizer'].includes(val),
    then: Yup.string().required('Please provide a last name.'),
  }),
  organisation_name: Yup.string()
    .nullable()
    .when('role', {
      is: (val) => ['deliverer'].includes(val),
      then: Yup.string().required('Please provide an organisation name.'),
    }),
});
