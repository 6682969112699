import { Column, usePagination, useSortBy, useTable } from 'react-table';

type Props = {
  columns: Column[];
  data: Record<string, unknown>[];
  pagy_metadata: any[];
  manualSortBy?: boolean;
  sortBy: null | { id: string; desc: boolean };
};

export default function useCustomTable(props: Props) {
  const { manualSortBy, pagy_metadata, columns, data, sortBy } = props;

  return useTable(
    {
      columns,
      data,
      manualSortBy,
      disableMultiSort: true,
      autoResetSortBy: false,
      initialState: {
        pageSize: 20,
        pageCount: pagy_metadata.pages,
        manualPagination: true,
        sortBy: sortBy ? [sortBy] : [],
      },
    },
    useSortBy,
    usePagination,
  );
}
