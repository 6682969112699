import React from 'react';
import { CellValue } from 'react-table';

type Props = {
  value: CellValue;
};

function AddressCell({ value }: Props) {
  const { address_line_1, address_line_2 } = value;

  return (
    <div className="px-6 py-4">
      <p className="text-sm leading-5 font-medium text-gray-700">{address_line_1}</p>
      <p className="text-sm leading-5 text-gray-500">{address_line_2}</p>
    </div>
  );
}

export default AddressCell;
