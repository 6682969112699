import { Inertia } from '@inertiajs/inertia';
import PageLayout from 'pages/PageLayout';
import React from 'react';

import Form from './Form';

export default function New(props) {
  const {
    activity_types,
    locations,
    projects,
    available_volunteers,
    booked_volunteers,
    other_users,
    referer,
  } = props;

  return (
    <Form
      activity_types={activity_types}
      locations={locations}
      projects={projects}
      onSubmit={handleSubmit}
      updateTimeframe={updateTimeframe}
      availableVolunteers={available_volunteers}
      bookedVolunteers={booked_volunteers}
      otherUsers={other_users}
    />
  );

  async function handleSubmit(values: any) {
    const user_activities_attributes = [
      ...values.volunteer_user_activities,
      ...values.other_user_activities,
    ];

    await Inertia.post('/activities', { ...values, user_activities_attributes, referer });
  }

  function updateTimeframe(start_time, end_time) {
    Inertia.visit('/activities/new', {
      data: {
        start_time,
        end_time,
      },
      replace: true,
      preserveState: true,
      only: ['available_volunteers', 'booked_volunteers'],
    });
  }
}

New.layout = (page: React.ReactNode) => <PageLayout title="Add Activity">{page}</PageLayout>;
