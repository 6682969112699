import { Inertia } from '@inertiajs/inertia';
import PageLayout from 'pages/PageLayout';
import React from 'react';

import Form from './Form';

export default function New(props) {
  const { locations, artists } = props;

  return <Form artists={artists} locations={locations} onSubmit={handleSubmit} />;

  async function handleSubmit(values: any) {
    await Inertia.post('/projects', values);
  }
}

New.layout = (page: React.ReactNode) => <PageLayout title="Add Project">{page}</PageLayout>;
