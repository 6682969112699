import React, { useEffect, useState } from 'react';

type Props = {
  value: any[];
  availableVolunteers: any[];
  bookedVolunteers: any[];
  handleChange: (value: any[]) => void;
};

export default function VolunteerInput(props: Props) {
  const { availableVolunteers, bookedVolunteers, value, handleChange } = props;

  const [available, setAvailable] = useState(availableVolunteers);

  useEffect(() => {
    const volunteer_ids = value.map((volunteer) => volunteer.user_id);
    const withoutSelected = availableVolunteers.filter(
      (volunteer) => !volunteer_ids.includes(volunteer.id),
    );

    setAvailable(withoutSelected);
  }, [value, availableVolunteers]);

  return (
    <div className="border-t border-b my-4 py-4">
      <label className="block text-lg font-medium leading-5 text-gray-700">Volunteers</label>
      <div className="my-3">
        {!value.length ? (
          <div className="text-gray-400 font-normal text-sm">
            No volunteers have been assigned to this activity yet.
          </div>
        ) : (
          <ul>
            {value.map(({ user }, index) => {
              return (
                <li className="text-gray-700 leading-6 text-sm" key={index}>
                  {user.first_name} {user.last_name}{' '}
                  <span>
                    <button
                      onClick={(event) => removeVolunteer(event, user)}
                      className="text-red-400 font-medium text-sm">
                      Remove
                    </button>
                  </span>
                </li>
              );
            })}
          </ul>
        )}
      </div>

      <label className="block text-sm font-medium leading-5 text-gray-700">Available</label>
      <div className="my-3">
        {!available.length ? (
          <div className="text-gray-400 text-sm">
            No volunteers are available for the selected date and time or all have been selected.
          </div>
        ) : (
          <ul>
            {available.map((volunteer, index) => {
              return (
                <li className="text-gray-700 leading-6 text-sm" key={index}>
                  {volunteer.first_name} {volunteer.last_name}{' '}
                  <span>
                    <button
                      onClick={(event) => addVolunteer(event, volunteer)}
                      className="text-green-400 font-medium text-sm">
                      Add
                    </button>
                  </span>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <label className="block text-sm font-medium leading-5 text-gray-700">
        Available But Booked Elsewhere
      </label>
      <div className="my-3">
        {!bookedVolunteers.length ? (
          <div className="text-gray-400 text-sm">
            No volunteers are available but booked for the selected date and time.
          </div>
        ) : (
          <ul>
            {bookedVolunteers.map((volunteer, index) => {
              return (
                <li className="text-gray-700 leading-6 text-sm" key={index}>
                  {volunteer.first_name} {volunteer.last_name}{' '}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );

  function addVolunteer(event, user) {
    event.preventDefault();

    handleChange([...value, { user_id: user.id, user }]);
  }

  function removeVolunteer(event, volunteerToRemove) {
    event.preventDefault();

    const withoutVolunteer = value.filter(
      (volunteer) => volunteer.user_id !== volunteerToRemove.id,
    );

    handleChange(withoutVolunteer);
  }
}
