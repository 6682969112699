import { Inertia } from '@inertiajs/inertia';
import PageLayout from 'pages/PageLayout';
import React from 'react';

import Form from './Form';

type Props = {
  project: any;
  artists: any[];
  locations: any[];
};

export default function Edit(props: Props) {
  const { artists, locations, project } = props;

  const projectWithTime = {
    ...project,
    start_time: new Date(project.start_time),
  };

  return (
    <Form
      initialValues={projectWithTime}
      artists={artists}
      locations={locations}
      onSubmit={handleSubmit}
    />
  );

  async function handleSubmit(values: any) {
    await Inertia.put(`/projects/${project.id}`, values);
  }
}

Edit.layout = (page: React.ReactNode) => <PageLayout title="Edit Project">{page}</PageLayout>;
