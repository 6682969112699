import React from 'react';

import Header from './UserHeader';

type Props = {
  children: React.ReactNode;
  title: string;
};

export default function UserPageLayout(props: Props) {
  const { children, title } = props;

  return (
    <div className="min-h-screen flex overflow-hidden bg-gray-100">
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <Header title={title} />

        <main
          className="relative z-0 w-full max-w-6xl mr-auto ml-auto overflow-y-auto p-4 focus:outline-none"
          tabIndex={0}>
          {children}
        </main>
      </div>
    </div>
  );
}
