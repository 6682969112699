import { InertiaLink, usePage } from '@inertiajs/inertia-react';
import React from 'react';

type Props = {
  href: string;
  children: React.ReactNode;
};

export default function MenuItem(props: Props) {
  const { children, href } = props;

  const { url } = usePage();

  const isActive = url.startsWith(href);

  const activeClasses = isActive ? 'bg-gray-100 text-gray-800 font-medium' : 'text-gray-600';

  return (
    <InertiaLink
      className={`group flex items-center px-2 py-2 text-sm rounded-md ${activeClasses}`}
      href={href}>
      {children}
    </InertiaLink>
  );
}
