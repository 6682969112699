import { userTypesMap } from 'components/constants/userTypes';
import React from 'react';

import MultiSelectInput from './MultiSelectInput';
import RadioInput from './RadioInput';

type Props = {
  handleChange: (value: any) => void;
  value: any;
  multiselect?: boolean;
};

const userTypes = Object.entries(userTypesMap).map(([value, label]: any) => {
  return { label, value };
});

function UserTypeInput(props: Props) {
  const { handleChange, multiselect, value } = props;

  if (multiselect) {
    return (
      <MultiSelectInput
        handleChange={handleChange}
        name="projectsMultiInput"
        options={userTypes}
        placeholder="User Types"
        value={value}
      />
    );
  }

  return (
    <RadioInput
      helperText="Important: Only organizers can add and updates users and activities."
      label="User Type"
      name="userType"
      handleChange={handleChange}
      value={value}
      options={userTypes}
    />
  );
}

export default UserTypeInput;
