import { Inertia } from '@inertiajs/inertia';
import { usePage } from '@inertiajs/inertia-react';
import { TextWithLinks } from 'components/TextWithLinks';
import dayjs from 'dayjs';
import React from 'react';
import ReactModal from 'react-modal';

type Props = {
  activity: any;
  closeModal: () => void;
};

ReactModal.setAppElement('#app');

export default function ActivityModal(props: Props) {
  const { closeModal, activity } = props;

  const page = usePage();

  return (
    <ReactModal
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(37, 47, 63, 0.65)',
          zIndex: 200,
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          position: 'static',
        },
      }}
      isOpen={!!activity}>
      {activity && (
        <div className="flex w-full sm:w-152 justify-between">
          <div className="w-full">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Activity Details</h3>
            </div>
            <div className="px-4 py-5 sm:p-0">
              <dl>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm leading-5 font-medium text-gray-500">Project</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {activity.project.code} ({activity.project.title})
                  </dd>
                </div>
                <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                  <dt className="text-sm leading-5 font-medium text-gray-500">Activity Type</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {activity.activity_type.name}
                  </dd>
                </div>
                <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                  <dt className="text-sm leading-5 font-medium text-gray-500">Venue</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    <p>{activity.location.name}</p>
                    <p>{activity.location.address_line_1}</p>
                    <p>{activity.location.address_line_2}</p>
                  </dd>
                </div>
                <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                  <dt className="text-sm leading-5 font-medium text-gray-500">Users</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {activity.users.length ? (
                      activity.users.map((user: any) => {
                        const isUserPerson = ['Organizer', 'Volunteer'].includes(user.type);

                        const label = isUserPerson
                          ? `${user.first_name} ${user.last_name} - ${user.type}`
                          : `${user.organisation_name} - ${user.type}`;

                        return <p key={user.id}>{label}</p>;
                      })
                    ) : (
                      <p className="text-gray-500">No users are linked to this activity.</p>
                    )}
                  </dd>
                </div>
                <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                  <dt className="text-sm leading-5 font-medium text-gray-500">Starts</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    <p>{dayjs(activity.start_time).format('dddd, MMMM D')}</p>
                    <p>{dayjs(activity.start_time).format('HH:mm')}</p>
                  </dd>
                </div>
                <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                  <dt className="text-sm leading-5 font-medium text-gray-500">Ends</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    <p>{dayjs(activity.end_time).format('dddd, MMMM D')}</p>
                    <p>{dayjs(activity.end_time).format('HH:mm')}</p>
                  </dd>
                </div>
                {activity.notes && (
                  <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                    <dt className="text-sm leading-5 font-medium text-gray-500">Notes</dt>
                    <dd className="mt-1 text-sm break-all leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                      <TextWithLinks>{activity.notes}</TextWithLinks>
                    </dd>
                  </div>
                )}
                <div className="mt-8 sm:mt-0 flex flex-wrap sm:border-t sm:border-gray-200 sm:py-5">
                  <button
                    type="button"
                    onClick={navigateToEditActivity}
                    className="flex shadow-sm sm:flex-1 sm:col-start-2 m-1 justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    Edit
                  </button>
                  <span className="m-1 sm:flex-1 flex w-full rounded-md shadow-sm sm:col-start-1">
                    <button
                      onClick={deleteActivity}
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-red-400 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      Delete
                    </button>
                  </span>
                </div>
              </dl>
            </div>
          </div>

          <svg
            onClick={closeModal}
            width="30"
            height="30"
            className="cursor-pointer text-gray-400 hover:text-gray-800"
            viewBox="0 0 24 24"
            fill="currentColor"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6 18L18 6M6 6L18 18"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </ReactModal>
  );

  function deleteActivity() {
    const isDeletionConfirmed = window.confirm('Are you sure you want to delete this item?');

    if (isDeletionConfirmed) {
      Inertia.delete(`/activities/${activity.id}`);
      closeModal();
    }
  }

  function navigateToEditActivity() {
    Inertia.get(`/activities/${activity.id}/edit`, { referer: page.url }, {});
  }
}
