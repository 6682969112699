import 'react-datepicker/dist/react-datepicker.css';

import be from 'date-fns/locale/en-GB';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { registerLocale } from 'react-datepicker';

import DateTimeRangeInput from './DateTimeRangeInput';

registerLocale('be', be);

type Props = {
  handleChange: (values: any[]) => void;
  value: any[];
};

export default function AvailabilityInput(props: Props) {
  const { value, handleChange } = props;

  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  return (
    <div className="mt-6 border-t">
      {!value.length ? (
        <div className="my-6 text-gray-600 text-sm">
          {' '}
          No availabilities have been added for this volunteer yet.
        </div>
      ) : (
        <ul className="divide-y divide-gray-200">
          {value
            .filter((availability) => !availability['_destroy'])
            .map((availability, index) => {
              const startDateFormatted = dayjs(availability.start_time).format('ddd, D MMM');
              const startTimeFormatted = dayjs(availability.start_time).format('HH:mm');
              const endTimeFormatted = dayjs(availability.end_time).format('HH:mm');

              return (
                <li key={index} className="py-4 flex items-center">
                  <svg
                    className="h-10 w-10 text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <div className="ml-3">
                    <p className="font-medium text-gray-700">
                      {startDateFormatted}
                      {'  '}
                      <span className="text-sm text-gray-400">
                        <button onClick={(event) => removeAvailability(event, index)}>
                          (Verwijderen)
                        </button>
                      </span>
                    </p>
                    <p className="text-sm text-gray-500">
                      {startTimeFormatted} to {endTimeFormatted}
                    </p>
                  </div>
                </li>
              );
            })}
        </ul>
      )}

      <div className="flex border-t pt-4">
        <DateTimeRangeInput
          startTimeValue={startTime}
          endTimeValue={startTime}
          handleStartTimeChange={setStartTime}
          handleEndTimeChange={setEndTime}
        />

        <button
          onClick={addAvailability}
          disabled={!startTime || !endTime}
          className="rounded-md self-end shadow-sm block mt-1 px-2 border text-green-400 border-green-400 disabled:border-gray-300 disabled:text-gray-300 relative sm:text-sm sm:leading-5">
          Voeg Toe
        </button>
      </div>
    </div>
  );

  function removeAvailability(event, index) {
    event.preventDefault();

    value[index]['_destroy'] = true;

    handleChange(value);
  }

  function addAvailability(event) {
    event.preventDefault();

    handleChange([...value, { start_time: startTime, end_time: endTime }]);

    setStartTime('');
    setEndTime('');
  }
}
