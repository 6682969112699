import React from 'react';
import { CellValue } from 'react-table';

type Props = {
  value: CellValue;
};

export default function LongTextCell({ value }: Props) {
  return (
    <div
      title={value}
      className="px-3 py-4 whitespace-no-wrap text-sm w-32 truncate leading-5 text-gray-500">
      {value}
    </div>
  );
}
