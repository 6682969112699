import React from 'react';
import { SliderPicker } from 'react-color';

type Props = {
  handleChange: (value: string) => void;
  value: string;
};

function ColorInput(props: Props) {
  const { value, handleChange } = props;

  return (
    <div className="mt-6 max-w-sm">
      <label htmlFor="colorInput" className="block text-sm font-medium leading-5 text-gray-700">
        Agenda Color
      </label>

      <div className="mt-1 form-input px-2 block rounded-md shadow-sm">
        <div
          className="rounded mb-4 text-white font-semibold text-sm px-2 p-1"
          style={{ backgroundColor: value }}>
          Example text for calendar display
        </div>
        <div className="mb-2">
          <SliderPicker onChange={(color) => handleChange(color.hex)} color={value} />
        </div>
      </div>
      <p className="mt-2 text-sm text-gray-500">Select a color that contrasts with white text.</p>
    </div>
  );
}

export default ColorInput;
