import React from 'react';
import Select, { components } from 'react-select';

type Props<ValueType = string> = {
  handleChange: (value: ValueType | null) => void;
  name: string;
  options: any;
  placeholder: string;
  value: any;
  isClearable?: boolean;
};

function SelectInput<ValueType = string>(props: Props<ValueType>) {
  const { value, isClearable, name, placeholder, handleChange, options } = props;

  const selectedOption = options.find((option) => option.value === value);

  return (
    <div className="mt-1 z-40">
      <Select
        components={{ DropdownIndicator }}
        id={name}
        name={name}
        isClearable={isClearable}
        classNamePrefix="react-select"
        onChange={(option: any) => (option ? handleChange(option.value) : handleChange(null))}
        options={options}
        placeholder={placeholder}
        styles={customStyles}
        value={selectedOption ? selectedOption : null}
        menuPortalTarget={document.querySelector('body')}
      />
    </div>
  );
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </components.DropdownIndicator>
  );
};

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '20rem',
    outline: 'none',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    paddingRight: '0.5rem',
    paddingLeft: '0.75rem',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    zIndex: 4,
    borderColor: state.isFocused ? '#a4cafe' : '#d2d6dc',
    borderRadius: '0.375rem',
    boxShadow: state.isFocused ? '0px 0px 0px 3px rgba(164,202,254,.45);' : 0,
    '&:hover': {
      borderColor: state.isFocused ? '#a4cafe' : '#d2d6dc',
    },
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  menu: (provided: any) => ({ ...provided, zIndex: '9999 !important' }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    width: '0px',
  }),
  input: (provided: any) => ({
    ...provided,
    lineHeight: '1.25',
    color: '#4a5568',
    fontSize: 14,
    boxShadow: '0 !important',
    '&:focus': {
      fontSize: 240,
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#a0aec0',
    fontSize: 14,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#4a5568',
    fontSize: 14,
  }),
  option: (provided: any) => ({
    ...provided,
    color: '#4a5568',
    fontSize: 14,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingRight: '0.5rem',
    paddingLeft: '0.75rem',
  }),
};

export default SelectInput;
