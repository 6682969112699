import { Inertia } from '@inertiajs/inertia';
import { Link } from '@inertiajs/inertia-react';
import PageLayout from 'pages/PageLayout';
import React from 'react';

export default function Index() {
  return (
    <div>
      <p className="text-red-500">
        Warning: This page allows deleting/modifying important data. Only use these commands if you
        know what you are doing.
      </p>

      <div className="border rounded p-4 mt-4">
        <h3 className="text-lg text-gray-700 font-semibold mb-2">
          Clear all projects, activities and availabilities
        </h3>
        <p className="font-semibold mb-2 text-gray-700">
          This removes all data that is specific to one season.
        </p>
        <p className="text-gray-700 mb-2">
          This should typically be done after a season to clean up finished activities and projects
          and start the new season with an empty database.
        </p>
        <p className="text-gray-700 font-semibold">
          This does not remove venues, users and activity types.
        </p>
        <button onClick={cleanTemporaryData} className="bg-red-500 text-white p-2 rounded mt-3">
          CLEAR PROJECTS, ACTIVITIES AND AVAILABILITIES
        </button>
      </div>

      <div className="border rounded p-4 mt-4">
        <h3 className="text-lg text-gray-700 font-semibold mb-2">Clear volunteer logs</h3>
        <p className="font-semibold mb-2 text-gray-700">
          This removes all the avtivity logs for all volunteers
        </p>
        <p className="text-gray-700">
          This should typically be done after most of the planning is done but before the event
          takes place,
        </p>
        <p className="text-gray-700 font-semibold">
          so the volunteers do not open the app with a huge list of logs that are not important
          anymore.
        </p>
        <button onClick={clearVolunteerLogs} className="bg-red-500 text-white p-2 rounded mt-3">
          CLEAR VOLUNTEER LOGS
        </button>
      </div>

      <div className="border rounded p-4 mt-4">
        <h3 className="text-lg text-gray-700 font-semibold mb-2">Export data</h3>
        <p className="font-semibold mb-2 text-gray-700">
          This allows exporting the data to csv format for storage.
        </p>

        <p className="text-gray-700">
          It should be noted that connections between resources (such as projects and activities)
          are denoted by ids.
        </p>

        <p className="text-gray-700">
          For example, an activity can have a project_id of 1, which corresponds to the project with
          id 1. Excel supports connecting these ids by way of foreign key columns.
        </p>

        <div className="mt-2">
          <a
            target="_blank"
            href="/data_control/export_activities_to_csv.csv"
            className="bg-gray-700 text-sm text-white px-2 py-1 rounded">
            EXPORT ACTIVITY DATA
          </a>
        </div>

        <div className="mt-2">
          <a
            target="_blank"
            href="/data_control/export_projects_to_csv.csv"
            className="bg-gray-700 text-sm text-white px-2 py-1 rounded">
            EXPORT PROJECT DATA
          </a>
        </div>

        <div className="mt-2">
          <a
            target="_blank"
            href="/data_control/export_activity_types_to_csv.csv"
            className="bg-gray-700 text-sm text-white px-2 py-1 rounded">
            EXPORT ACTIVITY TYPE DATA
          </a>
        </div>

        <div className="mt-2">
          <a
            target="_blank"
            href="/data_control/export_venues_to_csv.csv"
            className="bg-gray-700 text-sm text-white px-2 py-1 rounded">
            EXPORT VENUE DATA
          </a>
        </div>
      </div>
    </div>
  );

  function cleanTemporaryData() {
    if (window.confirm('Are you sure?')) {
      Inertia.post(`/data_control/clean_temporary_data`);
    }
  }

  function clearVolunteerLogs() {
    if (window.confirm('Are you sure?')) {
      Inertia.post(`/data_control/clear_volunteer_logs`);
    }
  }
}

Index.layout = (page: React.ReactNode) => <PageLayout title="Data Control">{page}</PageLayout>;
