import TextInput from 'components/inputs/TextInput';
import UserTypeInput from 'components/inputs/UserTypeInput';
import { FormikHelpers, useFormik } from 'formik';
import React from 'react';

type Props = {
  initialValues?: any;
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
  validationSchema: any;
};

export default function Form(props: Props) {
  const { initialValues, onSubmit, validationSchema } = props;

  const {
    handleSubmit,
    setFieldTouched,
    errors,
    touched,
    setFieldValue,
    values,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: Object.assign(
      {},
      {
        username: '',
        password: '',
        type: 'Volunteer',
        first_name: '',
        last_name: '',
        organisation_name: '',
        notes: '',
      },
      initialValues,
    ),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit,
    validationSchema,
    isInitialValid: false,
  });

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-6 sm:mt-5">
        <TextInput
          error={touched.username ? errors.username : ''}
          handleBlur={() => setFieldTouched('username')}
          handleChange={(value) => setFieldValue('username', value)}
          helperText="Usernames must be at least 4 characters long."
          label="Username"
          name="username"
          placeholder="jvandekerkhove"
          value={values.username}
        />

        <TextInput
          error={touched.password ? errors.password : ''}
          handleBlur={() => setFieldTouched('password')}
          handleChange={(value) => setFieldValue('password', value)}
          helperText="Passwords must be at least 6 characters long."
          label="Password"
          name="password"
          placeholder="password123"
          value={values.password}
        />

        <UserTypeInput handleChange={(value) => setFieldValue('type', value)} value={values.type} />

        {['Organizer', 'Volunteer'].includes(values.type) ? (
          <React.Fragment>
            <TextInput
              error={touched.first_name ? errors.first_name : ''}
              handleBlur={() => setFieldTouched('first_name')}
              handleChange={(value) => setFieldValue('first_name', value)}
              label="First Name"
              name="first_name"
              placeholder="Jan"
              value={values.first_name}
            />
            <TextInput
              error={touched.last_name ? errors.last_name : ''}
              handleBlur={() => setFieldTouched('last_name')}
              handleChange={(value) => setFieldValue('last_name', value)}
              label="Last Name"
              name="last_name"
              placeholder="Vandekerkhove"
              value={values.last_name}
            />
          </React.Fragment>
        ) : (
          <TextInput
            error={touched.organisation_name ? errors.organisation_name : ''}
            handleBlur={() => setFieldTouched('organisation_name')}
            handleChange={(value) => setFieldValue('organisation_name', value)}
            label="Organisation Name"
            name="organisation_name"
            placeholder="Pianos Maene"
            value={values.organisation_name}
          />
        )}

        <TextInput
          handleBlur={() => setFieldTouched('notes')}
          handleChange={(value) => setFieldValue('notes', value)}
          isOptional
          label="Opmerkingen / Evaluatie"
          name="notes"
          placeholder="Prefers to work alone"
          value={values.notes}
        />

        <div className="border-t border-gray-300 pt-3 mt-6">
          <button
            disabled={!isValid || isSubmitting}
            type="submit"
            className="inline-flex disabled:bg-gray-300 items-center shadow-sm px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
