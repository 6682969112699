import React from 'react';
import Linkify from 'react-linkify';

const componentDecorator = (href: string, text: string, key: number) => (
  <a className="break-all" href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

type Props = {
  children: React.ReactNode;
};

export function TextWithLinks({ children }: Props) {
  return <Linkify componentDecorator={componentDecorator}>{children}</Linkify>;
}
