import React from 'react';
import { HeaderGroup } from 'react-table';

type Props = {
  headerGroups: HeaderGroup[];
};

function Header(props: Props) {
  const { headerGroups } = props;

  return (
    <thead>
      {headerGroups.map((headerGroup, groupIndex) => (
        <tr key={groupIndex} {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, columnIndex) => (
            <th
              key={columnIndex}
              {...column.getHeaderProps(column.getSortByToggleProps())}
              className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-indigo-700 uppercase tracking-wider">
              <div className="flex items-center">
                {column.render('Header')}
                <span className="ml-1">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <svg
                        className="h-5 w-5"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    ) : (
                      <svg
                        className="h-5 w-5"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20">
                        <path d="M10.707 7.05L10 6.343 4.343 12l1.414 1.414L10 9.172l4.243 4.242L15.657 12z" />
                      </svg>
                    )
                  ) : (
                    <div className="h-5 w-5" />
                  )}
                </span>
              </div>
            </th>
          ))}
          <th className="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
        </tr>
      ))}
    </thead>
  );
}

export default Header;
