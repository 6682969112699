import { Inertia } from '@inertiajs/inertia';
import PageLayout from 'pages/PageLayout';
import React from 'react';

import Form from './Form';

type Props = {
  volunteer: any;
};

export default function Edit(props: Props) {
  const { volunteer } = props;

  return (
    <div>
      <h4 className="font-medium text-lg text-green-500">
        {volunteer.first_name} {volunteer.last_name}
      </h4>

      <Form initialValues={volunteer} onSubmit={handleSubmit} />
    </div>
  );

  async function handleSubmit(values: any) {
    await Inertia.put(`/availabilities/${volunteer.id}`, { volunteer: values });
  }
}

Edit.layout = (page: React.ReactNode) => (
  <PageLayout title="Update Availabilities">{page}</PageLayout>
);
