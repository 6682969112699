import 'react-big-calendar/lib/css/react-big-calendar.css';

import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import dayjs from 'dayjs';
import React from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';

import ActivityModal from './ActivityModal';

const locales = {
  be: require('date-fns/locale/be'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: (date: Date) => startOfWeek(date, { weekStartsOn: 1 }),
  getDay,
  locales,
});

type Props = {
  events: { title: string; start: Date; end: Date; color: string; activity: any }[];
};

function CalendarView(props: Props) {
  const { events } = props;

  const [selectedActivity, setSelectedActivity] = React.useState<any>(null);

  return (
    <div className="bg-white p-6 min-h-full shadow overflow-hidden sm:rounded-lg">
      <Calendar<
        { title: string; activity: any; color: string; start: Date; end: Date },
        Record<string, unknown>
      >
        step={30}
        scrollToTime={new Date(0, 0, 0, 8, 0, 0)}
        onSelectEvent={(event) => setSelectedActivity(event.activity)}
        min={new Date(0, 0, 0, 6, 0, 0)}
        views={['day', 'week', 'month']}
        localizer={localizer}
        events={events}
        defaultView="week"
        startAccessor="start"
        endAccessor="end"
        defaultDate={dayjs().month(7).date(1).toDate()}
        formats={{
          eventTimeRangeFormat: ({ start, end }) =>
            `${format(start, 'HH:mm')} - ${format(end, 'HH:mm')}`,
          dayFormat: 'EEEE, LLL d',
          dayHeaderFormat: 'EEEE, LLLL d',
          timeGutterFormat: 'HH:mm',
          monthHeaderFormat: 'LLLL yyyy',
          dayRangeHeaderFormat: ({ start, end }) =>
            `${format(start, 'LLLL d')} - ${format(end, 'LLLL d')}`,
        }}
        eventPropGetter={(event) => ({
          className: 'shadow',
          style: { backgroundColor: event.color, fontSize: 14 },
        })}
      />

      <ActivityModal activity={selectedActivity} closeModal={() => setSelectedActivity(null)} />
    </div>
  );
}

export default CalendarView;
