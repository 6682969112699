import 'react-datepicker/dist/react-datepicker.css';

import { Inertia } from '@inertiajs/inertia';
import { usePage } from '@inertiajs/inertia-react';
import ActivityTypeInput from 'components/inputs/ActivityTypeInput';
import LocationInput from 'components/inputs/LocationInput';
import LongTextCell from 'components/inputs/LongTextCell';
import ProjectInput from 'components/inputs/ProjectInput';
import SecondaryTextCell from 'components/inputs/SecondaryText';
import UsersInput from 'components/inputs/UsersInput';
import Table from 'components/table';
import DatetimeCell from 'components/table/cells/DatetimeCell';
import MainTextCell from 'components/table/cells/MainTextCell';
import useCustomTable from 'components/table/useCustomtable';
import dayjs from 'dayjs';
import { handleQueryFilter } from 'lib/handleQueryFilter';
import { handleQuerySort } from 'lib/handleQuerySort';
import PageLayout from 'pages/PageLayout';
import queryString from 'query-string';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

type Activity = { id: number; name: string; addressLine1: string; addressLine2: string };

type Props = {
  paged_activities: Activity[];
  pagy_metadata: any;
  locations: any[];
  projects: any[];
  activity_types: any[];
  users: any[];
  data: any[];
};

export default function Index(props: Props) {
  const { pagy_metadata, data, projects, locations, activity_types, users } = props;

  const page = usePage();
  const loadRef = useRef(false);

  const activities = React.useMemo(() => data, [props]);

  const [dateFilter, setDateFilter] = useState(handleQueryFilter('date_filter', { date: true }));

  const [activityTypeFilter, setActivityTypeFilter] = useState(
    handleQueryFilter('activity_types', { number: true }),
  );

  const [locationFilter, setLocationFilter] = useState(
    handleQueryFilter('locations', { number: true }),
  );

  const [projectFilter, setProjectFilter] = useState(
    handleQueryFilter('projects', { number: true }),
  );

  const [userFilter, setUserFilter] = useState(handleQueryFilter('users', { number: true }));

  const sortBy = useMemo(() => handleQuerySort(queryString), [queryString]);

  const columns = useMemo(
    () => [
      {
        Header: 'Start Time',
        accessor: 'start_time',
        Cell: ({ cell }) => <DatetimeCell value={cell.value} />,
      },
      {
        Header: 'End Time',
        accessor: 'end_time',
        Cell: ({ cell }) => <DatetimeCell value={cell.value} />,
      },
      {
        Header: 'Type',
        accessor: 'activity_type.name',
        Cell: ({ cell }) => <SecondaryTextCell value={cell.value} />,
      },
      {
        Header: 'Venue',
        accessor: 'location.name',
        Cell: ({ cell }) => <SecondaryTextCell value={cell.value} />,
      },
      {
        Header: 'Project',
        accessor: 'project.code',
        Cell: ({ cell }) => <MainTextCell value={cell.value} />,
      },
      {
        Header: 'Users',
        accessor: 'users',
        disableSortBy: true,
        Cell: ({ cell }) => {
          const value = cell.value
            .map((user) => {
              return user.first_name
                ? `${user.first_name} ${user.last_name[0]}.`
                : user.organisation_name;
            })
            .join(', ');

          return <LongTextCell value={value} />;
        },
      },
      {
        Header: 'Remarks',
        accessor: 'notes',
        Cell: ({ cell }) => <LongTextCell value={cell.value} />,
      },
    ],
    [],
  );

  const tableInstance = useCustomTable({
    columns,
    data: activities,
    manualSortBy: true,
    pagy_metadata,
    sortBy,
  });

  useEffect(() => {
    if (!loadRef.current) {
      loadRef.current = true;
      return;
    }

    refetch();
  }, [
    activityTypeFilter,
    locationFilter,
    projectFilter,
    userFilter,
    dateFilter,
    tableInstance.state.sortBy,
  ]);

  return (
    <div>
      <div className="mb-4">
        <span className="inline-flex rounded-md shadow-sm">
          <button
            onClick={navigateToNewActivityPath}
            type="button"
            className="inline-flex items-center px-4 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
            Add
          </button>
        </span>
      </div>

      <div className="flex flex-wrap mb-6">
        <ProjectInput
          multiselect
          value={projectFilter}
          data={projects}
          handleChange={(value) => setProjectFilter(value as any[])}
        />

        <LocationInput
          multiselect
          value={locationFilter}
          data={locations}
          handleChange={(value) => setLocationFilter(value as any[])}
        />

        <ActivityTypeInput
          multiselect
          value={activityTypeFilter}
          data={activity_types}
          handleChange={(value) => setActivityTypeFilter(value as any[])}
        />

        <UsersInput
          value={userFilter}
          data={users}
          handleChange={(value) => setUserFilter(value as any[])}
        />

        <DatePicker
          className="form-input text-gray-600 border-gray-300 p-2.5 mt-1 relative rounded-md shadow-sm block sm:text-sm sm:leading-5"
          dateFormat="dd/MM/yyyy"
          selected={dateFilter}
          onChange={(value) => setDateFilter(value as any[])}
          isClearable
          placeholderText="Filter op datum"
        />
      </div>

      <Table
        tableInstance={tableInstance}
        extraActions={[
          (element) => (
            <button
              className="text-indigo-600 hover:text-indigo-800 focus:outline-none focus:underline"
              onClick={() => duplicateActivity(element)}>
              Duplicate
            </button>
          ),
        ]}
        onEdit={handleOnEdit}
        pagy_metadata={pagy_metadata}
        onDelete={handleOnDelete}
      />
    </div>
  );

  function refetch() {
    const { sortBy } = tableInstance.state;

    const query = {
      activity_types: activityTypeFilter,
      locations: locationFilter,
      projects: projectFilter,
      users: userFilter,
    };

    if (dateFilter) {
      query['date_filter'] = dayjs(dateFilter).format('DD-MM-YYYY');
    }

    if (sortBy.length) {
      const { id, desc } = sortBy[0];

      query['sort_by'] = `${id}_${desc ? 'DESC' : 'ASC'}`;
    }

    Inertia.get('/activities', query, {
      preserveState: true,
      replace: true,
      only: ['data', 'pagy_metadata'],
    });
  }

  function navigateToNewActivityPath() {
    Inertia.visit('/activities/new');
  }

  function duplicateActivity(element) {
    Inertia.post(`/activities/${element.id}/duplicate`);
  }

  async function handleOnDelete(id: string) {
    await Inertia.delete(`/activities/${id}`);
  }

  async function handleOnEdit(id: string) {
    await Inertia.get(`/activities/${id}/edit`, { referer: page.url }, {});
  }
}

Index.layout = (page: React.ReactNode) => <PageLayout title="Activities">{page}</PageLayout>;
