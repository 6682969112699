import dayjs from 'dayjs';
import queryString from 'query-string';
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export function handleQueryFilter(string, options) {
  let filters = [];

  if (options.date) {
    const value = queryString.parse(location.search)[string];

    if (value) {
      return dayjs(value, 'DD-MM-YYYY').toDate();
    } else {
      return null;
    }
  }

  const arrayValue = queryString.parse(location.search)[`${string}[]`];

  if (arrayValue) {
    filters = filters.concat(arrayValue);
  }

  if (options.number) {
    return filters.map((filterId) => {
      if (filterId === 'ALL_VOLUNTEERS' || filterId === 'ALL_CGB') {
        return filterId;
      }

      return parseInt(filterId);
    });
  } else {
    return filters;
  }
}
