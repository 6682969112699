import ArtistInput from 'components/inputs/ArtistInput';
import ColorInput from 'components/inputs/ColorInput';
import DatetimeInput from 'components/inputs/DatetimeInput';
import LocationInput from 'components/inputs/LocationInput';
import TextInput from 'components/inputs/TextInput';
import { FormikHelpers, useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

type Props = {
  initialValues?: any;
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
  locations: any[];
  artists: any[];
};

function Form(props: Props) {
  const { artists, initialValues, locations, onSubmit } = props;

  const {
    handleSubmit,
    setFieldTouched,
    errors,
    touched,
    setFieldValue,
    values,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: Object.assign(
      {},
      {
        agenda_color: 'blue',
        artist_id: '',
        code: '',
        description: '',
        location_id: '',
        start_time: '',
        title: '',
      },
      initialValues,
    ),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit,
    validationSchema,
    isInitialValid: false,
  });

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-6 sm:mt-5">
        <TextInput
          error={touched.code ? errors.code : ''}
          handleBlur={() => setFieldTouched('code')}
          handleChange={(value) => setFieldValue('code', value)}
          label="Code"
          name="code"
          placeholder="INL"
          value={values.code}
        />

        <TextInput
          error={touched.title ? errors.title : ''}
          handleBlur={() => setFieldTouched('title')}
          handleChange={(value) => setFieldValue('title', value)}
          label="Title"
          name="title"
          placeholder="Inleiding"
          value={values.title}
        />

        <TextInput
          handleBlur={() => setFieldTouched('description')}
          handleChange={(value) => setFieldValue('description', value)}
          isOptional
          label="Description"
          name="description"
          placeholder="Algemeen Project"
          value={values.description}
        />

        <DatetimeInput
          label="Start Time"
          name="start_time"
          placeholder="02/10/20 20:00"
          value={values.start_time}
          handleChange={(value) => setFieldValue('start_time', value)}
        />

        <LocationInput
          data={locations}
          value={values.location_id}
          handleChange={(value) => setFieldValue('location_id', value)}
        />

        <ArtistInput
          data={artists}
          value={values.artist_id}
          handleChange={(value) => setFieldValue('artist_id', value)}
        />

        <ColorInput
          value={values.agenda_color}
          handleChange={(value) => setFieldValue('agenda_color', value)}
        />

        <div className="border-t border-gray-300 pt-3 mt-6">
          <button
            disabled={!isValid || isSubmitting}
            type="submit"
            className="inline-flex disabled:bg-gray-300 items-center shadow-sm px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  code: Yup.string().required('Please provide a project code.'),
  title: Yup.string().required('Please provide a title code.'),
});

export default Form;
