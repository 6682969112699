import React from 'react';

import DropdownInput from './DropdownInput';

type Props = {
  handleChange: (value: number | null) => void;
  data: any[];
  value: any;
};

export default function ArtistInput(props: Props) {
  const { data, handleChange, value } = props;

  const artists = data.map((artist) => ({ label: artist.organisation_name, value: artist.id }));

  return (
    <DropdownInput
      handleChange={handleChange}
      label="Artist"
      name="artist"
      options={artists}
      placeholder="Vox Luminis"
      isOptional
      isClearable
      value={value}
    />
  );
}
