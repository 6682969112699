import { Inertia } from '@inertiajs/inertia';
import ActivityTypeInput from 'components/inputs/ActivityTypeInput';
import LocationInput from 'components/inputs/LocationInput';
import ProjectInput from 'components/inputs/ProjectInput';
import UsersInput from 'components/inputs/UsersInput';
import dayjs from 'dayjs';
import { handleQueryFilter } from 'lib/handleQueryFilter';
import PageLayout from 'pages/PageLayout';
import React, { useEffect } from 'react';

import CalendarView from './CalendarView';
import CallsheetView from './callsheet-view';

type Props = {
  data: any[];
  locations: any[];
  projects: any[];
  users: any[];
  activity_types: any[];
};

export default function Index(props: Props) {
  const { data, projects, locations, activity_types, users } = props;

  const [activeView, setActiveView] = React.useState<'calendar' | 'callsheet'>('calendar');

  const [printActivityNotes, setPrintActivityNotes] = React.useState<boolean>(false);
  const [printDescription, setPrintDescription] = React.useState<boolean>(false);

  const [activityTypeFilter, setActivityTypeFilter] = React.useState(
    handleQueryFilter('activity_types', { number: true }),
  );

  const [locationFilter, setLocationFilter] = React.useState(
    handleQueryFilter('locations', { number: true }),
  );

  const [projectFilter, setProjectFilter] = React.useState(
    handleQueryFilter('projects', { number: true }),
  );

  const [userFilter, setUserFilter] = React.useState(handleQueryFilter('users', { number: true }));

  useEffect(() => {
    Inertia.get(
      '/dashboard',
      {
        activity_types: activityTypeFilter,
        locations: locationFilter,
        projects: projectFilter,
        users: userFilter,
      },
      { preserveState: true, replace: true, only: ['data'] },
    );
  }, [activityTypeFilter, locationFilter, projectFilter, userFilter]);

  const events = data.map((activity) => ({
    title: `${activity.project.code} - ${activity.activity_type.name}`,
    start: dayjs(activity.start_time).toDate(),
    end: dayjs(activity.end_time).toDate(),
    activity,
    color: activity.project.agenda_color,
  }));

  return (
    <div className="flex flex-col flex-1">
      <div className="mb-6 bg-white pt-5 p-6 shadow sm:rounded-lg">
        <div className="pb-3 mb-3 border-b ">
          <p className="text-gray-800 font-semibold text-md">View</p>
          <span className="relative z-0 inline-flex shadow-sm">
            <button
              type="button"
              onClick={() => setActiveView('calendar')}
              className={`relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium focus:z-10 focus:outline-none transition ease-in-out duration-150 ${
                activeView === 'calendar'
                  ? 'bg-indigo-500 text-white border-gray-300'
                  : 'text-gray-700 hover:text-gray-500'
              }`}>
              Calendar
            </button>
            <button
              type="button"
              onClick={() => setActiveView('callsheet')}
              className={`-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium  focus:z-10 focus:outline-none transition ease-in-out duration-150 ${
                activeView === 'callsheet'
                  ? 'bg-indigo-500 text-white border-gray-300'
                  : 'text-gray-700 hover:text-gray-500'
              }`}>
              Callsheet
            </button>
          </span>
        </div>
        <div>
          <p className="text-gray-800 font-semibold text-md">Filters</p>
          <div className="flex flex-wrap">
            <ProjectInput
              multiselect
              value={projectFilter}
              data={projects}
              handleChange={(value) => setProjectFilter(value as any[])}
            />

            <LocationInput
              multiselect
              value={locationFilter}
              data={locations}
              handleChange={(value) => setLocationFilter(value as any[])}
            />

            <ActivityTypeInput
              multiselect
              value={activityTypeFilter}
              data={activity_types}
              handleChange={(value) => setActivityTypeFilter(value as any[])}
            />

            <UsersInput
              value={userFilter}
              data={users}
              handleChange={(value) => setUserFilter(value as any[])}
            />
          </div>

          {activeView === 'callsheet' && (
            <div className="mt-3">
              <p className="text-gray-800 mt-1 font-semibold text-md">Print Options</p>
              <div>
                <div className="flex items-center">
                  <div className="flex mt-2 items-start">
                    <div className="absolute flex items-center h-5">
                      <input
                        id="print_activity_notes"
                        type="checkbox"
                        checked={printActivityNotes}
                        onClick={() => setPrintActivityNotes(!printActivityNotes)}
                        className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                      />
                    </div>
                    <div className="pl-7 text-sm leading-5">
                      <label htmlFor="print_activity_notes" className="font-medium text-gray-700">
                        Include activity notes
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex mt-2 items-start">
                    <div className="absolute flex items-center h-5">
                      <input
                        id="print_description"
                        type="checkbox"
                        checked={printDescription}
                        onClick={() => setPrintDescription(!printDescription)}
                        className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                      />
                    </div>
                    <div className="pl-7 text-sm leading-5">
                      <label htmlFor="print_description" className="font-medium text-gray-700">
                        Include project description
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {activeView === 'calendar' ? (
        <CalendarView events={events} />
      ) : (
        <CallsheetView
          printDescription={printDescription}
          printActivityNotes={printActivityNotes}
          activities={data}
        />
      )}
    </div>
  );
}

Index.layout = (page: React.ReactNode) => <PageLayout title="Overview">{page}</PageLayout>;
