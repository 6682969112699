import React from 'react';

import DropdownInput from './DropdownInput';
import MultiSelectInput from './MultiSelectInput';

type Props = {
  handleChange: (value: any) => void;
  data: any[];
  value: any;
  multiselect?: boolean;
};

export default function LocationInput(props: Props) {
  const { data, handleChange, multiselect, value } = props;

  const locations = data.map(([id, name]) => ({ label: name, value: id }));

  if (multiselect) {
    locations.unshift({ label: 'Alle Concertgebouw', value: 'ALL_CGB' });

    return (
      <MultiSelectInput
        handleChange={handleChange}
        name="locationsMultiInput"
        options={locations}
        placeholder="Venues"
        value={value}
      />
    );
  }

  return (
    <DropdownInput
      handleChange={handleChange}
      label="Venue"
      name="venue"
      options={locations}
      placeholder="Stadsschouwburg"
      value={value}
    />
  );
}
