import React from 'react';

import DropdownInput from './DropdownInput';
import MultiSelectInput from './MultiSelectInput';

type Props = {
  handleChange: (value: any) => void;
  data: any[];
  value: any;
  multiselect?: boolean;
};

export default function ProjectInput(props: Props) {
  const { data, handleChange, multiselect, value } = props;

  const projects = data.map(([id, code]) => ({ label: code, value: parseInt(id) }));

  if (multiselect) {
    return (
      <MultiSelectInput
        handleChange={handleChange}
        name="projectsMultiInput"
        options={projects}
        placeholder="Projects"
        value={value}
      />
    );
  }

  return (
    <DropdownInput
      handleChange={handleChange}
      label="Project"
      name="project"
      options={projects}
      placeholder="ALG"
      value={value}
    />
  );
}
