import { Inertia } from '@inertiajs/inertia';
import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime'; // load on demand
import UserPageLayout from 'pages/UserPageLayout';
import React, { useEffect } from 'react';

dayjs.extend(RelativeTime); // use plugin

export default function VolunteerLog(props) {
  const { logs } = props;

  useEffect(() => {
    Inertia.post(
      '/activities/read_logs',
      {},
      { preserveState: true, preserveScroll: true, replace: true },
    );
  }, []);

  return <div>{generateNotification()}</div>;

  function generateNotification() {
    if (!logs) {
      return;
    }

    return logs.map(
      ({ id, log_type, created_at, location, activity_type, start_time, end_time, notes }) => {
        let text = '';

        switch (log_type) {
          case 'created': {
            text = 'You were added to an activity.';
            break;
          }
          case 'updated': {
            text = 'One of your activities was updated.';
            break;
          }
          case 'deleted': {
            text = 'You were removed from an activity.';
            break;
          }
        }

        return (
          <div
            key={id}
            className="bg-white flex flex-col w-full mb-5 shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-3 border-b border-gray-200 sm:px-6">
              <h3 className="text-indigo-700 font-medium text-sm">{text}</h3>
              <div className="mt-1">
                <p className="text-sm text-gray-700 font-semibold leading-5">
                  {dayjs(start_time).format('dddd, MMMM D')}, {dayjs(end_time).format('HH:mm')} -{' '}
                  {dayjs(end_time).format('HH:mm')}
                </p>
                <p className="text-sm text-gray-600 mt-1 leading-5">
                  {activity_type.name}, {location.name}
                </p>
                {notes && <p className="text-sm text-gray-600 mt-1 italic leading-5">"{notes}"</p>}
              </div>
              <p className="text-gray-400 mt-2 text-xs">{dayjs(created_at).fromNow()}</p>
            </div>
          </div>
        );
      },
    );
  }
}

VolunteerLog.layout = (page: React.ReactNode) => (
  <UserPageLayout title="Logs">{page}</UserPageLayout>
);
