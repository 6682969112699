import { Inertia } from '@inertiajs/inertia';
import Table from 'components/table';
import MainTextCell from 'components/table/cells/MainTextCell';
import useCustomTable from 'components/table/useCustomtable';
import { handleQuerySort } from 'lib/handleQuerySort';
import PageLayout from 'pages/PageLayout';
import queryString from 'query-string';
import React, { useEffect, useMemo, useRef } from 'react';

type Volunteer = { id: number; username: string };

type Props = {
  data: Volunteer[];
  pagy_metadata: any;
};

export default function Index(props: Props) {
  const { data, pagy_metadata } = props;

  const loadRef = useRef(false);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Username',
        accessor: 'username',
        Cell: ({ cell }) => <MainTextCell value={cell.value} />,
      },
    ],
    [],
  );

  const availabilities = React.useMemo(() => data, [props]);

  const sortBy = useMemo(() => handleQuerySort(queryString), [queryString]);

  const tableInstance = useCustomTable({
    columns,
    data: availabilities,
    manualSortBy: true,
    pagy_metadata,
    sortBy,
  });

  useEffect(() => {
    if (!loadRef.current) {
      loadRef.current = true;
      return;
    }

    refetch();
  }, [tableInstance.state.sortBy]);

  return (
    <div>
      <Table
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
        pagy_metadata={pagy_metadata}
        tableInstance={tableInstance}
        actions={(element: any) => (
          <button
            onClick={() => handleOnEdit(element.id)}
            className="text-indigo-600 hover:text-indigo-800 focus:outline-none focus:underline">
            Update Availabilities
          </button>
        )}
      />
    </div>
  );

  function refetch() {
    const { sortBy } = tableInstance.state;

    const query = {};

    if (sortBy.length) {
      const { id, desc } = sortBy[0];

      query['sort_by'] = `${id}_${desc ? 'DESC' : 'ASC'}`;
    }

    Inertia.get('/availabilities', query, {
      preserveState: true,
      replace: true,
      only: ['data', 'pagy_metadata'],
    });
  }

  async function handleOnDelete(id: string) {
    await Inertia.delete(`/availabilities/${id}`);
  }

  async function handleOnEdit(volunteer_id: string) {
    await Inertia.visit(`/availabilities/${volunteer_id}/edit`);
  }
}

Index.layout = (page: React.ReactNode) => <PageLayout title="Availabilities">{page}</PageLayout>;
