import { Inertia } from '@inertiajs/inertia';
import LongTextCell from 'components/inputs/LongTextCell';
import SearchInput from 'components/inputs/SearchInput';
import Table from 'components/table';
import AddressCell from 'components/table/cells/AddressCell';
import MainTextCell from 'components/table/cells/MainTextCell';
import useCustomTable from 'components/table/useCustomtable';
import { handleQuerySort } from 'lib/handleQuerySort';
import PageLayout from 'pages/PageLayout';
import queryString from 'query-string';
import React, { useEffect, useMemo, useRef } from 'react';

type Location = { id: number; name: string; addressLine1: string; addressLine2: string };

type Props = {
  data: Location[];
  pagy_metadata: any;
};

export default function Index(props: Props) {
  const { data, pagy_metadata } = props;

  const loadRef = useRef(false);

  const sortBy = useMemo(() => handleQuerySort(queryString), [queryString]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ cell }) => <MainTextCell value={cell.value} />,
      },
      {
        Header: 'Address',
        Cell: ({ row }) => <AddressCell value={row.original} />,
      },
      {
        Header: 'Remarks',
        accessor: 'notes',
        disableSortBy: true,
        Cell: ({ cell }) => <LongTextCell value={cell.value} />,
      },
    ],
    [],
  );

  const [searchFilter, setSearchFilter] = React.useState('');

  const locations = React.useMemo(() => data, [props]);

  const tableInstance = useCustomTable({
    columns,
    data: locations,
    manualSortBy: true,
    pagy_metadata,
    sortBy,
  });

  useEffect(() => {
    if (!loadRef.current) {
      loadRef.current = true;
      return;
    }

    refetch(searchFilter);
  }, [tableInstance.state.sortBy]);

  return (
    <div>
      <div className="mb-4">
        <span className="inline-flex rounded-md shadow-sm">
          <button
            onClick={navigateToNewLocationPath}
            type="button"
            className="inline-flex items-center px-4 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
            Add
          </button>
        </span>
      </div>

      <div className="flex items-center mb-6">
        <SearchInput
          name="search_string"
          placeholder="Search by name or address"
          value={searchFilter}
          handleChange={(value) => setSearchFilter(value)}
          onSearch={() => refetch(searchFilter)}
          onClear={clearSearch}
        />
      </div>

      <Table
        pagy_metadata={pagy_metadata}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
        tableInstance={tableInstance}
      />
    </div>
  );

  function navigateToNewLocationPath() {
    Inertia.visit('/locations/new');
  }

  async function handleOnDelete(id: string) {
    await Inertia.delete(`/locations/${id}`);
  }

  async function handleOnEdit(id: string) {
    await Inertia.visit(`/locations/${id}/edit`);
  }

  function clearSearch() {
    setSearchFilter('');
    refetch('');
  }

  function refetch(search_string) {
    const { sortBy } = tableInstance.state;

    const query = {};

    if (search_string) {
      query['search_string'] = search_string;
    }

    if (sortBy.length) {
      const { id, desc } = sortBy[0];

      query['sort_by'] = `${id}_${desc ? 'DESC' : 'ASC'}`;
    }

    Inertia.get('/locations', query, {
      preserveState: true,
      replace: true,
      only: ['data', 'pagy_metadata'],
    });
  }
}

Index.layout = (page: React.ReactNode) => <PageLayout title="Venues">{page}</PageLayout>;
