import React from 'react';
import { CellValue } from 'react-table';

type Props = {
  value: CellValue;
};

export default function SecondaryTextCell({ value }: Props) {
  return <p className="px-3 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">{value}</p>;
}
