import { Inertia } from '@inertiajs/inertia';
import dayjs from 'dayjs';
import UserPageLayout from 'pages/UserPageLayout';
import React, { useEffect } from 'react';
import ReactToPrint from 'react-to-print';

export default function VolunteerOverview(props) {
  const { activities } = props;

  const [dataByDay, setDataByDay] = React.useState<any[]>([]);

  const printRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const intervalFunction = () => Inertia.reload({ only: ['activities'] });

    const handler = setInterval(intervalFunction, 5000);

    return () => clearInterval(handler);
  }, []);

  useEffect(() => {
    if (!activities) {
      return;
    }

    const newData = activities.reduce((accumulator, activity) => {
      const activityDay = dayjs(activity.start_time).format('dddd, MMMM D');

      const index = accumulator.findIndex((dataByDay: any) => dataByDay.day === activityDay);

      if (index === -1) {
        return accumulator.concat([{ day: activityDay, activities: [activity] }]);
      } else {
        accumulator[index].activities.push(activity);
        return accumulator;
      }
    }, []);

    setDataByDay(newData);
  }, [activities]);

  if (!activities) {
    return <div>Loading...</div>;
  }

  if (!activities.length) {
    return <div>No activities have been added for you.</div>;
  }

  return (
    <div>
      <ReactToPrint
        pageStyle="padding: 30px;"
        copyStyles
        trigger={() => (
          <button
            type="button"
            className="inline-flex mb-2 items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
            Print Overview
          </button>
        )}
        content={() => printRef.current}
      />
      <div>
        {dataByDay.map(({ day, activities }) => {
          return (
            <div
              key={day}
              className="bg-white flex flex-col w-full mb-5 shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-3 border-b border-gray-200">
                <h3 className="text-md leading-6 font-medium text-indigo-700">{day}</h3>
              </div>
              <div className="px-4 py-4 sm:p-0 overflow-x-auto">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Time
                      </th>

                      <th className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        What
                      </th>
                      <th className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Where
                      </th>
                      <th className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Who
                      </th>
                      <th className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Notes
                      </th>
                      <th className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Project
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {activities.map((activity: any) => {
                      return (
                        <tr key={activity.id}>
                          <td className="px-3 py-3 w-24 border-b border-gray-200 font-medium text-sm leading-5 text-gray-700">
                            {dayjs(activity.start_time).format('HH:mm')} -{' '}
                            {dayjs(activity.end_time).format('HH:mm')}
                          </td>

                          <td className="px-3 py-3 w-40  border-b border-gray-200 font-medium text-sm leading-5 text-gray-900">
                            {activity.activity_type.name}
                          </td>

                          <td className="px-3 py-3 w-56 border-b border-gray-200 text-sm leading-5 text-gray-700">
                            {activity.location.name}
                          </td>
                          <td className="px-3 py-3 w-32 border-b border-gray-200 text-sm leading-5 text-gray-700">
                            {activity.users.map((user) => {
                              const lastIndex = activity.users[activity.users.length - 1].id;

                              return (
                                <span key={user.id}>
                                  {user.first_name
                                    ? `${user.first_name} ${user.last_name[0]}.`
                                    : user.organisation_name}
                                  {lastIndex !== user.id && ', '}
                                </span>
                              );
                            })}
                          </td>
                          <td className="px-3 py-3 w-40 border-b border-gray-200 text-sm leading-5 text-gray-700">
                            {activity.notes}
                          </td>
                          <td className="px-3 py-3 w-32 border-b border-gray-200 text-sm leading-5 text-gray-700">
                            {activity.project.code}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}

        <div className="hidden">
          <div ref={printRef}>
            {dataByDay.map(({ day, activities }) => {
              return (
                <div
                  key={day}
                  className="bg-white flex flex-col w-full mb-5 shadow overflow-hidden sm:rounded-lg">
                  <div className="px-2 py-2 border-b border-gray-200">
                    <h3 className="text-md leading-6 font-medium text-indigo-700">{day}</h3>
                  </div>
                  <div className="px-2 py-2 sm:p-0 overflow-x-auto">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="px-1 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Time
                          </th>

                          <th className="px-1 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            What
                          </th>

                          <th className="px-1 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Where
                          </th>
                          <th className="px-1 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Who
                          </th>
                          <th className="px-1 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Notes
                          </th>
                          <th className="px-1 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Project
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {activities.map((activity: any) => {
                          return (
                            <tr key={activity.id}>
                              <td className="px-1 py-1 border-b border-gray-200 text-xs leading-5 text-gray-900">
                                <p>{dayjs(activity.start_time).format('HH:mm')}</p>
                                <p>{dayjs(activity.end_time).format('HH:mm')}</p>
                              </td>

                              <td className="px-1 py-1  border-b border-gray-200 font-medium text-xs leading-5 text-gray-900">
                                {activity.activity_type.name}
                              </td>

                              <td className="px-1 py-1 border-b border-gray-200 text-xs leading-5 text-gray-900">
                                {activity.location.name}
                              </td>
                              <td className="px-1 py-1 border-b border-gray-200 text-xs leading-5 text-gray-900">
                                {activity.users.map((user: any) => {
                                  const lastIndex = activity.users[activity.users.length - 1].id;

                                  return (
                                    <span key={user.id}>
                                      {user.first_name
                                        ? `${user.first_name} ${user.last_name[0]}.`
                                        : user.organisation_name}
                                      {lastIndex !== user.id && ', '}
                                    </span>
                                  );
                                })}
                              </td>
                              <td className="px-1 py-1 border-b border-gray-200 text-xs leading-5 text-gray-900">
                                {activity.notes}
                              </td>
                              <td className="px-1 py-1 border-b border-gray-200 text-xs leading-5 text-gray-900">
                                {activity.project.code}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

VolunteerOverview.layout = (page: React.ReactNode) => (
  <UserPageLayout title="Users">{page}</UserPageLayout>
);
