import '../styles/tailwind.css';

import { createInertiaApp } from '@inertiajs/inertia-react';
import { InertiaProgress } from '@inertiajs/progress';
import React from 'react';
import { render } from 'react-dom';

//  Inertia Progress bar

InertiaProgress.init({
  color: '#f25858',
});

// Creates Inertia app

createInertiaApp({
  resolve: (name) => {
    const page = require(`../pages/${name}`).default;

    return page;
  },
  setup({ el, App, props }) {
    render(<App {...props} />, el);
  },
});
