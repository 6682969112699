import { Inertia } from '@inertiajs/inertia';
import PageLayout from 'pages/PageLayout';
import React from 'react';

import Form from './Form';

type Props = {
  location: any;
};

export default function Edit(props: Props) {
  const { location } = props;

  return <Form initialValues={location} onSubmit={handleSubmit} />;

  async function handleSubmit(values: any) {
    await Inertia.put(`/locations/${location.id}`, values);
  }
}

Edit.layout = (page: React.ReactNode) => <PageLayout title="Edit Venue">{page}</PageLayout>;
