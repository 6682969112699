import 'react-datepicker/dist/react-datepicker.css';

import be from 'date-fns/locale/en-GB';
import dayjs from 'dayjs';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

registerLocale('be', be);

type Props = {
  error?: string;
  label: string;
  name: string;
  handleChange: (value: Date) => void;
  placeholder: string;
  range?: 'future' | 'past';
  value: Date;
  greaterThenDate?: Date;
};

function DatetimeInput(props: Props) {
  const { name, greaterThenDate, label, placeholder, value, handleChange, error, range } = props;

  return (
    <div className="w-64 mt-6">
      <label className="block z- text-sm font-medium leading-5 text-gray-700" htmlFor={name}>
        {label}
      </label>
      <DatePicker
        selected={value}
        className={`form-input mt-1 relative rounded-md shadow-sm block sm:text-sm sm:leading-5 ${
          error ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300' : ''
        }`}
        onChange={(newDate: Date) => handleChange(newDate)}
        name={name}
        autoComplete="off"
        popperClassName="z-40"
        showTimeSelect
        timeFormat="HH:mm"
        dateFormat="dd/MM/yy HH:mm"
        timeIntervals={15}
        locale="be"
        timeCaption="time"
        openToDate={dayjs().month(7).date(1).toDate()}
        placeholderText={placeholder}
        {...(range === 'future' && { minDate: new Date() })}
        {...(range === 'past' && { maxDate: new Date() })}
        {...(greaterThenDate && { minDate: greaterThenDate })}
      />
      {error && <p className="text-red-500 text-xs mt-1 italic">{error}</p>}
    </div>
  );
}

export default DatetimeInput;
