import React from 'react';

type Props = {
  handleChange: (value: string) => void;
  name: string;
  placeholder: string;
  value: string;
  onSearch: () => void;
  onClear: () => void;
};

export default function SearchInput(props: Props) {
  const { value, handleChange, onClear, onSearch, name, placeholder } = props;

  return (
    <div className="w-64">
      <div className="relative rounded-md shadow-sm">
        <input
          className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
          id={name}
          name={name}
          onChange={(event) => handleChange(event.target.value)}
          placeholder={placeholder}
          type={'text'}
          value={value}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
          <button className="mr-2" onClick={onClear}>
            <svg
              className="h-5 w-5 text-gray-700"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <button onClick={onSearch}>
            <svg
              className="h-5 w-5 text-gray-700"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
