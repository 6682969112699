import { Inertia } from '@inertiajs/inertia';
import PageLayout from 'pages/PageLayout';
import React from 'react';

import Form from './Form';

export default function New() {
  return <Form onSubmit={handleSubmit} />;

  async function handleSubmit(values: any) {
    await Inertia.post('/locations', values);
  }
}

New.layout = (page: React.ReactNode) => <PageLayout title="Add Venue">{page}</PageLayout>;
