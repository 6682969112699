import React from 'react';

import DropdownInput from './DropdownInput';
import MultiSelectInput from './MultiSelectInput';

type Props = {
  handleChange: (value: any) => void;
  data: any[];
  value: any;
  multiselect?: boolean;
};

export default function ActivityTypeInput(props: Props) {
  const { data, handleChange, multiselect, value } = props;

  const activityTypes = data.map(([id, name]) => ({ label: name, value: id }));

  if (multiselect) {
    return (
      <MultiSelectInput
        handleChange={handleChange}
        name="activityTypesMultiInput"
        options={activityTypes}
        placeholder="Activity Types"
        value={value}
      />
    );
  }

  return (
    <DropdownInput
      handleChange={handleChange}
      label="Activity Type"
      name="activity_type"
      options={activityTypes}
      placeholder="Tuning"
      value={value}
    />
  );
}
