import { InertiaLink } from '@inertiajs/inertia-react';
import React from 'react';

type Props = {
  pagy_metadata: any;
};

export default function Pagination(props: Props) {
  const { pagy_metadata } = props;

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="hidden sm:block">
        <p className="text-sm leading-5 text-gray-700">
          Showing
          <span className="font-medium"> {pagy_metadata.from} </span>
          to
          <span className="font-medium"> {pagy_metadata.to} </span>
          of
          <span className="font-medium"> {pagy_metadata.count} </span>
          results
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <InertiaLink
          as="button"
          disabled={!pagy_metadata.prev}
          href={pagy_metadata.prev_url}
          only={['data', 'pagy_metadata']}
          preserveState
          className="relative disabled:text-gray-300 disabled:border-gray-300 border-indigo-400 text-indigo-400 inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
          Previous
        </InertiaLink>
        <InertiaLink
          as="button"
          disabled={!pagy_metadata.next}
          href={pagy_metadata.next_url}
          only={['data', 'pagy_metadata']}
          preserveState
          className="ml-3 disabled:text-gray-300 disabled:border-gray-300 border-indigo-400 text-indigo-400 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
          Next
        </InertiaLink>
      </div>
    </div>
  );
}
